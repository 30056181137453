import styled from 'styled-components';
import { elevation, transition, black } from 'Utilities';

export const Card = styled.div`
  background: white;
  border-radius: 5px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: ${black};
  ${elevation[4]};
  ${transition({})};
  &:hover {
    ${elevation[5]};
  }
`;
